import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, DoBootstrap, Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];


import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,

} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { AuthService } from './services/auth.service';
import { NgxPermissionsService, NgxPermissionsModule } from 'ngx-permissions';
import { HttpInterceptorModule } from './interceptors/http.interceptor.module';
import { NgxMaskModule } from 'ngx-mask';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { NbThemeModule,  NbLayoutModule, NbButtonModule, NbCardModule, NbSidebarModule, NbInputModule, NbListModule} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbChatModule } from './chat/chat.module';
import { FilePickerModule } from  'ngx-awesome-uploader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    NbThemeModule.forRoot({ name: 'COSMIC_THEME' }),
    NbEvaIconsModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpInterceptorModule,
    NgxPermissionsModule.forRoot(),
    NgxMaskModule.forRoot(),
    QRCodeModule,
    NgxPrintModule,
    PopoverModule.forRoot(),
    ImageCropperModule,
    ToasterModule.forRoot(),
    IconModule,
    IconSetModule.forRoot(),
    NbLayoutModule,
    NbButtonModule,
    NbCardModule,
    NbSidebarModule.forRoot(),
    NbInputModule,
    NbListModule,
    NbChatModule.forRoot({ messageGoogleMapKey: 'MAP_KEY' }),
    FilePickerModule,
    NgMultiSelectDropDownModule.forRoot()
      
        
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS
    ],
      providers: [
    IconSetService,{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },
    ToasterService,
    AuthService,
  {
    provide: APP_INITIALIZER,
    useFactory: (as: AuthService, ps: NgxPermissionsService) =>
      function () {
        return as
          .load()
          .then((data: any) => {
            if (data && !data.error) {
              as.saveUser(data);
              ps.flushPermissions();
              const role = localStorage.getItem('role');
              //if (role == 'Store Manager' || role == 'Administrator') {
              if (role == 'Store Manager') {
                // console.log('STORE MANAGER', data.roles[0].name);
                return ps.addPermission(['AUTHENTICATED', 'STOREMANAGER']);
              } else if (role == 'Franchise Owner') {
                // console.log('FRANCHISE OWNER', data.roles[0].name);
                return ps.addPermission(['AUTHENTICATED', 'FRANCHISEOWNER']);
              }
            }
            ps.flushPermissions();
            return ps.addPermission([]);
          })
          .catch((err) => {
            as.changeIsLogoutClicked(true);
            ps.flushPermissions();
            return ps.addPermission([]);
          });
      },
    deps: [AuthService, NgxPermissionsService],
    multi: true
  }
  ],
  bootstrap: [AppComponent],
  
})


export class AppModule {}
 
