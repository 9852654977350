import { Injectable } from '@angular/core';
import { User } from "../models/User";
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedInUser: User = {};
  auth_token: string = '';
  isLogoutClicked: boolean = false;
  public franchise = {};
  public franchiseStores: any[] = [];
  constructor(
    private loginService: LoginService
  ) { }

  /**
   * set auth token
   */
  setAuthorizationToken(_token: string) {
    localStorage.removeItem('access_token');
    this.auth_token = _token;
    if (this.auth_token) { localStorage.setItem('access_token', this.auth_token); }

  }
  /**
   * save user data in local storage
   * @param user user Data
   */
  saveUser(user: any) {
    // User
    // console.log('Save User');
    localStorage.removeItem('loggedInUser');
    this.loggedInUser = user;
    // console.log(user, this.loggedInUser);
    localStorage.setItem('loggedInUser', JSON.stringify(this.loggedInUser));
  }
  /**
   * save franchise data in local storage
   * @param franchise franchise Data
   */
  saveFranchise(franchise: any) {
    // Franchise
    localStorage.removeItem('franchise');
    this.franchise = franchise;
    localStorage.setItem('franchise', JSON.stringify(this.franchise));
  }
  /**
   * save franchiseStores data in local storage
   * @param franchiseStores franchise Data
   */
  saveFranchiseStores(franchiseStores: any) {
    // FranchiseStores
    localStorage.removeItem('franchiseStores');
    this.franchiseStores = franchiseStores; 
    localStorage.setItem('franchiseStores', JSON.stringify(this.franchiseStores));
  }
  /**
   * get user data from local storage
   *
   */
  getLoggedInUser() {
    // User
    console.log('Loggedin user Retrieved');
    
    return JSON.parse(localStorage.getItem('loggedInUser'));
  }
  /**
   * get franchise data from local storage
   *
   */
  getFranchise() {
    // Franchise
    return JSON.parse(localStorage.getItem('franchise'));
  }
  /**
   * get user data from local storage
   *
   */
  getFranchiseStores() {
    // FranchiseStores
    return JSON.parse(localStorage.getItem('franchiseStores'));
  }
  /**
   * get authorization token
   */
  getAuthorizationToken() {
    this.auth_token = localStorage.getItem('access_token');
    return this.auth_token ? this.auth_token : '';
  }

  /**
   * check if user loffed in or not
   */
  checkIfLoggedIn() {
    const token = this.getAuthorizationToken();
    if (token && token.length > 0) {
      return true;
    }
    return false;
  }

  /**
   * check user logout or not
   */
  changeIsLogoutClicked(val: boolean) {
    this.isLogoutClicked = val;
  }

  getIsLogoutClicked(): boolean {
    return this.isLogoutClicked;
  }
  /**
   * clear auth data
   */
  clearStorage() {
    this.loggedInUser = {};
    this.franchiseStores = [];
    this.franchise = {};
    this.auth_token = '';
    localStorage.clear();
  }

  async load(): Promise<any> {
    if (this.checkIfLoggedIn()) {

      return this.loginService.getLoggedInUser();
    } else {
      return new Promise((res: any, rej: any) => {
        rej({});
      });
    }
  }
  
  async updateLoggedInUserData(): Promise<any> {
    if (this.checkIfLoggedIn()) {
      this.loginService.getLoggedInUser().then((data: any) => {
        this.saveUser(data);
        console.log("LoggedIn user updated successfully.");
      }, (error: any) => {
        console.log('Unable to fetch user details');
        console.log("LoggedIn user updated Error: ", error);
      }).catch(error => {
        console.log("LoggedIn user updated Error: ", error);
      });
    } else {
      return new Promise((res: any, rej: any) => {
        rej({});
      });
    }
  }

}
