import { Injectable } from '@angular/core';
import { GlobalProviderService } from '../providers/global-provider.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public userTimezoneId: any = "EST";
  public storeTimezoneId: any = 'EST'

  constructor(
    public globalProvider: GlobalProviderService,
    public http: HttpClient
  ) {
  }

  /**
   * Get and Set user's timezone_id
   * @param userTimezoneId 
   */
  public setUserTimezoneId(userTimezoneId) {
    console.log(' time zone set ==>',userTimezoneId)
    return this.userTimezoneId = userTimezoneId;
  }

  public getUserTimezoneId() {
    return this.userTimezoneId;
  }

  public setStoreTimezoneId(storeTimezoneId) {
    console.log('store time zone set ==>',storeTimezoneId)
    return this.storeTimezoneId = storeTimezoneId;
  }
  public getStoreTimezoneId() {
    return this.storeTimezoneId;
  }

  /**
  * authentication of store admin
  */
  async login(loginObj) {
    const bodyObj = {
      'username': loginObj.username,
      'password': loginObj.password
      // 'grant_type': 'password',
      // 'client_id': 2,
      // 'client_secret': 'gi14dlSW67vuvrbAsGO6crVWOWjddVrZLxifUQeb'
    };
    const result = await this.http
      .post<any>(this.globalProvider.SITE_URL + 'api/signin', bodyObj)
      .toPromise()
      .catch(err => {
        return err.error;
      });
    return result;

  }
  /**
   * get logged in user
   */
  async getLoggedInUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet('getuser')
        .then((data: any) => {
          //const currentStoreId = JSON.parse(localStorage.getItem('autologin_store_id'));
          //const timeZoneId = currentStoreId ? data?.user_stores?.find(user => user?.id === currentStoreId).timezone_id : data?.user_stores?.[0].timezone_id;
          // Set user timezone_id
          if (data != null && data != {}) {
            //this.setUserTimezoneId(timeZoneId);
            this.setUserTimezoneId(data['timezone_id']);

              if(localStorage.getItem('autologin_store_id')){
                const storeID= JSON.parse(localStorage.getItem('autologin_store_id'))
                this.setStoreTimezoneId(data['user_stores']?.find((store)=>store.id===storeID)?.timezone_id);          
              }
              else{
                this.setStoreTimezoneId(data['user_stores'][0]?.timezone_id);
              }           
          }
          else {
            this.setUserTimezoneId("EST");
          }
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * user verification
   */

  async userVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet('userVerification').then(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }
}
