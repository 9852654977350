import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIResponse } from '../models/APIResponse';
import { environment } from '../../environments/environment';
import momentFormat from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GlobalProviderService {
  public currentIndex = 0;
  public SITE_URL: string = environment.ADMIN_URL;
  public DOS_IMAGE_URL: string = environment.DOS_IMAGE_URL;

  public ONE_SIGNAL_APP_ID: string = environment.ONE_SIGNAL_APP_ID;

  public HERE_MAP_KEY: string = environment.HERE_MAP_KEY;
  // public SITE_URL: string = 'https://admin-dev.ngdel.com/';

  // public REGISTRATION_URL = 'https://admin-dev.ngdel.com/registration';
  public REGISTRATION_URL = environment.ADMIN_URL + 'registration';

  public API_URL: string = environment.API_URL;
  // public API_URL: string = 'https://nextglotto.com/lotto-admin/public/api/store_admin/';

  public ENABLE_TESTING: number = environment.ENABLE_TESTING;

  //public EMAIL_VALIDATION_PATTERN = '^[\w\d][\w]{0,2}(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
  public EMAIL_VALIDATION_PATTERN = '^[a-zA-Z0-9][a-z0-9A-Z._%+-]+@[a-z0-9]+\.[a-z]{2,4}$';
  //public EMAIL_VALIDATION_PATTERN = '^(?=.*[a-zA-Z])[a-zA-Z0-9._%+-]*@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$';

  public HELP_EMAIL = 'razordelivery@gmail.com';
  public HELP_PHONE_NO = '(999) 999-9999';

  public QR_SUCCESS_AUDIO = 'assets/audio/success.mp3';
  public QR_FAILURE_AUDIO = 'assets/audio/error.mp3';

  public SANDBOX_APP_ID = 'sandbox-sq0idb-127Ou6W5Ujsw3UGMKAbqeQ';
  public GOOGLE_MAP_API_KEY = environment.google_map.api_key;
  public TIMEZONE_GOOLGE_KEY = environment.google_map.timezone_api_key;
  isLoggedIn = false;
  // store redirect url
  redirectUrl: String;

  /**
   * List of Icons used in Map
   */
  //  { [key: string]: string }
  public MAP_ICONS: { name: string, icon: string }[] = [{
    name: "Scheduled Orders",
    icon: 'assets/img/40px orange pin 1.png',
  }, {
    name: "En-Route Orders",
    icon: 'assets/img/black_dot_25.png',

  }, {
    name: "Store Location",
    icon: 'assets/img/store_icon_1.svg',

  }, {
    name: "Driver Location",
    icon: 'assets/img/gif/driver_edited.gif',
  }
  ];
  public colorPairs = [
    { bgStrokeColor: 'rgb(54, 61, 226)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/driver_edited.gif' },
    { bgStrokeColor: 'rgb(0, 255, 170)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/00FF7F.gif' },
    { bgStrokeColor: 'rgb(0, 255, 255)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/00FFFF.gif' },
    { bgStrokeColor: 'rgb(60, 179, 113)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/3CB371.gif' },
    { bgStrokeColor: 'rgb(75, 0, 130)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/4B0082.gif' },
    { bgStrokeColor: 'rgb(106, 90, 205)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/6A5ACD.gif' },
    { bgStrokeColor: 'rgb(138, 43, 226)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/8A2BE2.gif' },
    { bgStrokeColor: 'rgb(139, 0, 139)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/8B008B.gif' },
    { bgStrokeColor: 'rgb(72, 209, 204)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/48D1CC.gif' },
    { bgStrokeColor: 'rgb(34, 139, 34)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/228B22.gif' },
    { bgStrokeColor: 'rgb(70, 130, 180)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/4682B4.gif' },
    { bgStrokeColor: 'rgb(0, 100, 0)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/006400.gif' },
    { bgStrokeColor: 'rgb(0, 128, 128)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/008080.gif' },
    { bgStrokeColor: 'rgb(34, 152, 201)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/Blue.gif' },
    { bgStrokeColor: 'rgb(196, 0, 255)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/C400FF.gif' },
    { bgStrokeColor: 'rgb(233, 150, 122)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/E9967A.gif' },
    { bgStrokeColor: 'rgb(240, 128, 128)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/F08080.gif' },
    { bgStrokeColor: 'rgb(255, 140, 0)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FF8C00.gif' },
    { bgStrokeColor: 'rgb(255, 105, 180)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FF69B4.gif' },
    { bgStrokeColor: 'rgb(255, 20, 147)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FF1493.gif' },
    { bgStrokeColor: 'rgb(255, 69, 0)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FF4500.gif' },
    { bgStrokeColor: 'rgb(255, 99, 71)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FF6347.gif' },
    { bgStrokeColor: 'rgb(255, 160, 122)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FFA07A.gif' },
    { bgStrokeColor: 'rgb(255, 182, 193)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FFBC61.gif' },
    { bgStrokeColor: 'rgb(255, 215, 0)', strokeColor: '#FFFFFF', driverIcon: 'assets/img/gif/FFD700.gif' }
  ];

  constructor(
    private http: HttpClient,
  ) { }

  /**
 * Performs HTTP POST Request on given API End Point with given POST data
 * @param apiEndPoint API End Point to perform POST request on
 * @param postObj POST Data to be sent in request
 */
  httpPost(apiEndPoint: string, postObj: Object) {
    return new Promise((resolve, reject) => {
      this.http.post(this.API_URL + apiEndPoint, postObj)
        .subscribe((res: APIResponse) => {
          if (res.success && res.data != null) {
            resolve(res.data);
          } else {
            reject(res.message);
          }
        }, err => {
          console.log('post', err);
          reject(err);
        });
    });
  }

  /**
 * Performs HTTP Put Request on given API End Point with given Put data
 * @param apiEndPoint API End Point to perform Put request on
 * @param postObj POST Data to be sent in request
 */
  httpPut(apiEndPoint: string, postObj: Object) {
    return new Promise((resolve, reject) => {
      this.http.put(this.API_URL + apiEndPoint, postObj)
        .subscribe((res: APIResponse) => {
          if (res.success && res.data != null) {
            resolve(res.data);
          } else {
            reject(res.message);
          }
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  }

  /**
   * Performs HTTP GET Request on given api endpoint
   * @param apiEndPoint API End-point to perform GET request on
   */
  httpGet(apiEndPoint: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.API_URL + apiEndPoint)
        .subscribe((res: APIResponse) => {
          if (res.success && res.data != null) {
            resolve(res.data);
          } else {
            reject(res.message);
          }
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  }

  /**
   * Performs HTTP GET Request on given api endpoint
   * @param apiEndPoint API End-point to perform GET request on
   */
  externalHttpGet(apiEndPoint: string) {
    return new Promise((resolve, reject) => {
      this.http.get(apiEndPoint)
        .subscribe((res: APIResponse) => {
          resolve(res);
        }, err => {
          console.error(err);
          reject(err);
        });
    });
  };

  appendCurrentTime(date: string) {
    // Parse the given date string as a moment object
    let dateDeadline = momentFormat(date, "YYYY-MM-DD");

    // Get the current time
    const currentTime = momentFormat();

    // Set the current time to the dateDeadline
    dateDeadline.hours(currentTime.hours());
    dateDeadline.minutes(currentTime.minutes());
    dateDeadline.seconds(currentTime.seconds());

    console.log("date===>", dateDeadline);
    return dateDeadline;
  }

  //for fix color assign
  getNextColorPair() {
    const colorPair = this.colorPairs[this.currentIndex % this.colorPairs.length];
    this.currentIndex++;
    return colorPair;
  }
}
