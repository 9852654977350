export const environment = {
  production: false,
    ADMIN_URL: 'https://razordelivery-api-dev.razordelivery.com/',
    API_URL: 'https://razordelivery-api-dev.razordelivery.com/api/',
    WEBSITE_URL: 'https://razordelivery-api-dev.razordelivery.com',
  // ADMIN_URL: 'http://127.0.0.1:8007/',
  // API_URL: 'http://127.0.0.1:8007/api/',
  // WEBSITE_URL: 'http://127.0.0.1:8007',
  pubnub: {
    pk: 'pub-c-93daec15-2b8d-43e6-90c6-af0759fd3219',
    sk: 'sub-c-edfb99d8-9d56-4122-be76-5ad2e5b97240',
  },
  stripe: {
    pk: 'pk_test_51H7HJ5KpKU0LHGEcJ7FNdhv6Avhjcb1nvedZOHELemyN8wnjBWwvur0Yg4gjUEgKo1pAJ0TwT6rKhlJJLnHH4jjH00Q4APgYIh'
  },
  google_map: {
    api_key: 'AIzaSyAZtiTib6CgTxwsqBnQnDxSDiMLwHJ5ve4',
    timezone_api_key: 'AIzaSyA-cn5JF-YMf5RAX9FFONFNV-_Z2z7fYG8'
  },
  chat_prefixes: {
    store: 'store_',
    user: 'user_',
    // store: '',
    // user: '',
  },

  recaptchaSiteKey: '6LdZbOIZAAAAAINT9fs-4JIXwWg-srNAP7ZCq5ZR',
  DOS_IMAGE_URL: 'https://lottos3-dev.nyc3.digitaloceanspaces.com/razor-delivery/',
  ONE_SIGNAL_APP_ID: 'f0e69998-4fd4-4ecf-ac51-55a2dbab1661',
  WEBSITE_SUPPORT_EMAIL: 'support@razordelivery.com',
  WEBSITE_SUPPORT_NUMBER: '(917) 633-5909',

  HERE_MAP_KEY: 'ouS-4SIFPwuB9rbqCZ8cXPVbyBklvZTKkpA6V8r_N8M',

  ENABLE_TESTING: 1, //0-false 1-true

  // HERE_MAP_KEY: 'KrTTuG0woJJoXs6qYt3l2UisrpK_RPfduLqpvGPyiqM'
};