import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

import { NgxPermissionsService } from 'ngx-permissions';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
    constructor(
        private router: Router,
        private auth: AuthService,
        private toastr: ToasterService,
        private permissionService: NgxPermissionsService
    ) { }
    static requestCount: number = 0;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url;
        if (req.url.indexOf('login') == -1) {
            // Get the auth token from the service.
            const authToken = this.auth.getAuthorizationToken();

            const storeId = localStorage.getItem('currentStoreId') || '';

            // Clone the request and set the new header with storeid
            const authReq = req.clone({ headers: req.headers.append('Authorization', 'Bearer ' + authToken).append('Storeid', storeId)});

            InterceptedHttp.requestCount++;
            // send cloned request with header to the next handler.
            return next.handle(authReq)
                .pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {

                        }
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status == 401) {
                            this.toastr.pop('error', 'Error', 'Enter a valid Email Id & Password.');
                            // this.toastr.pop('error', 'Session expired', 'Your Session has expired. Please sign in');
                            this.auth.clearStorage();
                            this.permissionService.flushPermissions();
                            this.auth.changeIsLogoutClicked(true);
                            this.router.navigate(['login']);
                        }
                        return throwError(error);
                    }));
        } else {
            const _req = req.clone({ setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            return next.handle(_req);
        }
    }
}
