import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ChatService } from './services/chat.service';
import { ToasterConfig } from 'angular2-toaster';
import { OneSignal } from 'onesignal-ngx';
// import {GlobalProviderService} from './services/global-provider';
import { GlobalProviderService } from './providers/global-provider.service';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<nb-layout>
  <nb-layout-column>
      <router-outlet></router-outlet>
      <toaster-container [toasterconfig]="config"></toaster-container>
  </nb-layout-column>
</nb-layout>`
})
export class AppComponent implements OnInit, AfterViewInit {
  public config: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    tapToDismiss: false,
    preventDuplicates: true,
  });
  constructor(private router: Router, 
          private chatService: ChatService, private oneSignal: OneSignal,public globalProvider: GlobalProviderService,) { 
            this.initializeOneSignal();

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    /**
     * Initialize Twilio Chat
     */
    this.initializeChatClient();
    // this.initializeOneSignal();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  
  }
  initializeOneSignal(){
    console.log("Init OS: ");
    this.oneSignal.init({
      appId: 'f0e69998-4fd4-4ecf-ac51-55a2dbab1661',
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerParam:{
        scope: '/onesignal'
      }
    }).then((res) => {
      console.log('One-Signal', res);
      // Once initialization is successful, execute the following:

      // Log the user ID (assuming it's defined somewhere in the code)

      // Prompt the user to subscribe to push notifications using a slide-down prompt
      this.oneSignal.Slidedown.promptPush();

    
    }).catch((err)=>{
      console.log('One-Signal ERROR', err);
    });
  }
  // Function to handle changes in the push subscription
   pushSubscriptionChangeListener(event: any) {
    // Check if the event contains a token (indicating a successful subscription)
    if (event.current.token) {
      // Log that a token has been received
      console.log(`The push subscription has received a token!`, event);

      // Add an alias 'app_user_id' to associate the OneSignal user with your app's user ID
      // this.oneSignal.User.addAlias('app_user_id', userId.toString());

      // you can save the device ID
      // saveDeviceId(event.current.id);
    }
  }

  // Function to handle changes in notification permission
   notificationPermissionChange(event: any) {
    // Log that the notification permission has changed
    console.log(`The notification permission change!`, event);

    // Add an alias 'app_user_id' to associate the OneSignal user with your app's user ID
    // this.oneSignal.User.addAlias('app_user_id', userId.toString());
  }

  // Function to handle foreground notifications
   handelForgroundNotification(event: any) {
    // Uncomment the following line to log foreground notifications
    // console.log(`Forground notification!`, event);

    // Call a function to display the notification to the user
    // showNotification(event.notification);

    // Return true to indicate that the notification should be displayed
    return true;
  }

  showNotification() {
    // Present an alert with the notification title and body
    
  }


  initializeChatClient() {
    // this.chatService.initChatClient();
  }

}
