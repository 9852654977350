import { Injectable } from '@angular/core';
import { GlobalProviderService } from '../providers/global-provider.service';
import * as TwilioChat from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private ACCESS_TOKEN = '';
  public CLIENT: TwilioChat.Client = null;
  public CHANNEL: Channel;
  public USER: TwilioChat.User;
  public USER_CHANNELS: Channel[] = [];
  public deliveryStaff = [];
  constructor(
    private global: GlobalProviderService
  ) { }

  /**
   * Initializes Chat Client for Twilio Chat Service
   */
  initChatClient() {
    return new Promise((resolve, reject) => {
      this.global.httpGet('chat/access_token')
        .then((res: any) => {
          this.ACCESS_TOKEN = res.token;
          TwilioChat.Client.create(this.ACCESS_TOKEN)
            .then((client) => {
              this.CLIENT = client;
              this.USER = this.CLIENT.user;
              console.log('THE CHAT USER: ', this.USER);
              resolve(true);
            });
        }, (err) => {
          console.log(err);
          reject(err);
        }).catch((err) => {
          console.log('Catch ERR: ', err);
          reject(err);
        });
    });
  }

  /**
   * Join an existing Chat Channel or Request new
   */
  checkClient(storeId: number, userId: number) {


  }

  joinChatChannel(storeId: number, userId: number) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (that.CLIENT == null) {
        that.initChatClient().then(() => {
          that.global.httpPost('chat/join_channel', { store_id: storeId, user_id: userId })
            .then((chatChannel: any) => {
              console.log(chatChannel);
              that.CLIENT.getChannelBySid(chatChannel.channel_sid)
                .then((chnl: Channel) => {
                  that.CHANNEL = chnl;
                  if (that.CHANNEL.status === 'joined') {
                    resolve(that.CHANNEL);
                  } else {
                    that.CHANNEL.join().then((ch) => {
                      resolve(ch);
                      console.log('Channel Joined: ', ch);
                    }).catch((err) => {
                      reject(err);
                      console.log('Error Joining Channel:', err);
                    });
                  }
                  console.log('Twilio Channel: ', that.CHANNEL);
                }, (err) => {
                  reject(err);
                  console.log(err);
                });
            }, (err) => {
              reject(err);
              console.log(err);
            }).catch((err) => {
              reject(err);
              console.log('Catch join_channel ERR: ', err);
            });
        });
      } else {
        that.global.httpPost('chat/join_channel', { store_id: storeId, user_id: userId })
          .then((chatChannel: any) => {
            console.log(chatChannel);
            that.CLIENT.getChannelBySid(chatChannel.channel_sid)
              .then((chnl: Channel) => {
                that.CHANNEL = chnl;
                if (that.CHANNEL.status === 'joined') {
                  resolve(that.CHANNEL);
                } else {
                  that.CHANNEL.join().then((ch) => {
                    resolve(ch);
                    console.log('Channel Joined: ', ch);
                  }).catch((err) => {
                    reject(err);
                    console.log('Error Joining Channel:', err);
                  });
                }
                console.log('Twilio Channel: ', that.CHANNEL);
              }, (err) => {
                reject(err);
                console.log(err);
              });
          }, (err) => {
            reject(err);
            console.log(err);
          }).catch((err) => {
            reject(err);
            console.log('Catch join_channel ERR: ', err);
          });
      }
    });
  }
}
